@import './vars/colors.scss';

.pac-container {
    box-shadow: 0 2px 4px rgba($black, 0.12);
}

body {
    background-color: $white;
}

.stat-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 14px;
    background-color: #e5e5e5;

    &:nth-child(even) {
        background-color: #fbfbfb;
    }
}
